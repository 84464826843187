import React, { useEffect } from "react"
import { Link } from "@reach/router"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => {
  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     setTimeout(() => {
  //       window.location.replace("/")
  //     }, 100)
  //   }
  // }, [])

  return (
    <Layout name="p-error">
      <Seo title="404: Not found" />
      <div className="a-container">
        <div className="p-error-content">
          <Link to="/">
            <h2>Ci dispiace, la pagina che cerchi non esiste</h2>
            Clicca qui per tornare alla home page
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
